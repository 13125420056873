<template>
    <modal @submit="onSubmit" @close="close">
        <template #header>
            <div class="flex items-center">
                <Icon :icon="['far', 'users']" class="mr-3 hidden sm:inline-block" />
                <h1 class="font-bold">{{ $t('professional.class_modal._title') }}</h1>
            </div>
            <div class="sm:hidden">
                <button class="btn" :class="{ 'btn--disabled': invalid }">
                    {{ $t('common.save') }}
                </button>
            </div>
        </template>
        <template>
            <div class="mb-4 flex items-center">
                <div class="w-full">
                    <InputDate v-model="fields.date" name="date" />
                </div>
            </div>
            <div class="mb-4 flex items-center">
                <div class="pr-4">{{ $t('common.from') }}</div>
                <div class="flex-auto">
                    <InputTime v-model="fields.startTime" name="start_time" :date="fields.date" />
                </div>
                <div class="px-4">{{ $t('common.to') }}</div>
                <div class="flex-auto">
                    <InputTime v-model="fields.endTime" name="end_time" :date="fields.date" />
                </div>
            </div>
            <div v-if="isRecurring && isEdit" class="mb-4">
                <div class="mb-4">
                    {{ $t('professional.recurrence_update_modes.is_instance') }}
                </div>
                <div class="flex items-center">
                    <div class="mr-2 flex-none">
                        {{ $t('professional.recurrence_update_modes.when_saving_update') }}
                    </div>
                    <InputSelect
                        v-model="fields.recurrenceUpdateMode"
                        name="update_mode"
                        :options="recurrenceUpdateModes"
                    />
                </div>
            </div>
            <div
                v-if="!(isEdit && fields.recurrenceUpdateMode === 'this')"
                class="mb-4 flex items-center"
            >
                <InputCheckbox v-if="!isEdit" v-model="isRecurring" class="flex-none" />
                <div v-if="isRecurring" class="flex w-full items-center">
                    <span>{{ $t('common.repeat') }}</span>
                    <div class="mx-2 w-16">
                        <InputText
                            v-model.number="fields.recurrence_rule.count"
                            name="occurrences"
                            type="number"
                            min="1"
                            max="20"
                        />
                    </div>
                    <span>{{ $tc('common.occurrence', fields.recurrence_rule.count) }}</span>
                </div>
                <div v-else class="cursor-pointer" @click="isRecurring = true">
                    {{ $t('common.no_repeat') }}
                </div>
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'users']" />
                </div>
                <div class="flex-none px-3">
                    {{ $t('professional.class_modal.max_event_regs') }}
                </div>
                <InputText
                    v-model="fields.maxEventRegistrations"
                    name="max_event_regs"
                    class="w-20"
                />
            </div>
            <div class="mb-4 flex items-center">
                <InputCheckbox v-model="isPrivate" class="flex-none" />
                <div>{{ $t('professional.class_modal.private') }}</div>
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'ticket']" class="rotate-45 transform" />
                </div>
                <InputSelect
                    v-model="fields.selectedVoucherType"
                    name="voucher_type_id"
                    :options="voucherTypes"
                    :placeholder="$t('professional.class_modal.voucher_type')"
                />
            </div>

            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'tag']" class="rotate-90 transform" />
                </div>
                <InputText
                    v-model="fields.title"
                    name="title"
                    :placeholder="$t('professional.class_modal.title')"
                />
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'location-dot']" />
                </div>
                <InputText
                    v-model="fields.location"
                    name="location"
                    :placeholder="$t('professional.class_modal.location')"
                />
            </div>
            <div class="mb-4 flex items-center">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'pen']" />
                </div>
                <InputTextArea
                    v-model="fields.notes"
                    name="notes"
                    rows="2"
                    :placeholder="$t('professional.class_modal.notes')"
                />
            </div>
            <div class="mb-2 mt-6 flex items-center text-lg">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'address-book']" />
                </div>
                <div v-if="isEdit">{{ $t('professional.event_modal.manage_registrations') }}</div>
                <div v-else>{{ $t('professional.event_modal.add_clients') }}</div>
            </div>
            <div class="mb-1">
                <InputText
                    v-model="search"
                    class="mr-3"
                    icon="magnifying-glass"
                    :placeholder="$t('common.search')"
                    debounced
                    @input="onSearch"
                />
            </div>
            <div class="mb-2">
                <div v-if="clients">
                    <InputSelectList
                        v-slot="item"
                        v-model="fields.selectedClients"
                        name="clients"
                        :placeholder="$t('professional.event_modal.no_clients_in_clientbook')"
                        :options="clients"
                        label="full_name"
                        class="h-64"
                        @input="selectClient"
                    >
                        <div class="flex items-center justify-start overflow-hidden">
                            <div v-if="item.user">
                                <Avatar :profile="item" size="small" class="flex-none" />
                            </div>
                            <div v-else-if="item.clients" class="flex">
                                <div v-for="client in item.clients.slice(0, 2)" :key="client.id">
                                    <div class="flex md:items-center">
                                        <div class="flex-none">
                                            <Avatar
                                                class="flex-none"
                                                :profile="client"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="item.clients.length > 3">
                                    <div class="flex flex-wrap md:items-center">
                                        <div class="flex-none">
                                            <Avatar
                                                class="flex-none !bg-neutral-darker"
                                                :profile="{
                                                    full_name: `+ ${item.clients.length - 2}`,
                                                }"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="item.clients.length === 3">
                                    <div class="flex flex-wrap md:items-center">
                                        <div class="flex-none">
                                            <Avatar
                                                class="flex-none"
                                                :profile="item.clients[2]"
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="item.full_name"
                                class="mx-4 truncate"
                                :class="{
                                    'line-through': cancelledClients && cancelledClients[item.id],
                                }"
                            >
                                {{ item.full_name }}
                            </div>
                            <div
                                v-if="item.name"
                                class="mx-4 truncate"
                                :class="{
                                    'line-through': cancelledClients && cancelledClients[item.id],
                                }"
                            >
                                {{ item.name }}
                            </div>
                            <div v-if="item.user" class="flex-none font-bold">
                                {{ vouchersForClient(item.id) }} x
                                <Icon
                                    :icon="['far', 'ticket']"
                                    class="rotate-45 transform text-primary"
                                />
                            </div>
                        </div>
                    </InputSelectList>
                </div>
                <div class="text-right">
                    {{ fields.allSelectedClients.length }} {{ $t('common.selected') }}
                </div>
            </div>
            <div class="mb-2 mt-6 flex items-center text-lg">
                <div class="w-8 text-lg">
                    <Icon :icon="['far', 'user-plus']" />
                </div>
                <div>{{ $t('professional.event_modal.invite_clients') }}</div>
            </div>
            <div class="mb-4 flex flex-wrap items-center pl-8">
                <InputTag
                    v-model="fields.emails"
                    validate="email"
                    name="client_emails"
                    :label="$t('professional.event_modal.invite_by_email')"
                    :placeholder="$t('professional.event_modal.emails')"
                />
            </div>
            <div class="mb-4 flex flex-wrap items-center pl-8">
                <InputTag
                    v-model="fields.phones"
                    validate="phone"
                    name="client_phonenumbers"
                    :label="$t('professional.event_modal.invite_by_phone')"
                    :placeholder="
                        $t('professional.event_modal.phones') + ' ' + $t('common.country_code')
                    "
                />
            </div>

            <div class="hidden justify-center sm:flex">
                <button
                    v-if="isEdit"
                    class="btn btn--danger mr-4"
                    @click.prevent="openEventDeleteDialog()"
                >
                    {{ $t('common.delete') }}
                </button>
                <button class="btn" :class="{ 'btn--disabled': invalid }">
                    {{ $t('common.save') }}
                </button>
            </div>

            <div class="flex justify-center sm:hidden">
                <button
                    v-if="isEdit"
                    class="link-danger flex items-center"
                    @click.prevent="openEventDeleteDialog()"
                >
                    <Icon :icon="['far', 'trash']" class="mr-2" />
                    <span>{{ $t('common.delete') }}</span>
                </button>
            </div>
        </template>
    </modal>
</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import Modal from '@/components/modals/Modal';
import InputText from '@/components/input/InputText';
import InputTextArea from '@/components/input/InputTextArea';
import InputSelect from '@/components/input/InputSelect';
import InputSelectList from '@/components/input/InputSelectList';
import InputTag from '@/components/input/InputTag';
import InputDate from '@/components/input/InputDate';
import InputTime from '@/components/input/InputTime';
import InputCheckbox from '@/components/input/InputCheckbox';
import Avatar from '@/components/general/Avatar';
import EventDeleteDialog from '@/components/modals/EventDeleteDialog.vue';
import {
    CALENDAR_EVENTS_FETCH,
    CALENDAR_SLOTS_FETCH,
    CLIENTS_FETCH,
    GROUPS_FETCH,
    REQUEST,
    VALIDATION_CREATE_MESSAGE,
} from '@/constants';

import moment from 'moment-timezone';

@Component({
    components: {
        Modal,
        InputText,
        InputTextArea,
        InputSelect,
        InputSelectList,
        InputDate,
        InputTime,
        InputTag,
        InputCheckbox,
        Avatar,
    },
})
export default class ClassModal extends Vue {
    fields = {
        selectedVoucherType: 3,
        maxEventRegistrations: 10,
        selectedClients: [],
        emails: [],
        phones: [],
        recurrence_rule: {
            frequency: 'WEEKLY',
            count: 4,
        },
        recurrenceUpdateMode: 'this',
        allSelectedClients: [],
        allGroups: [],
    };

    isRecurring = false;
    isPrivate = false;

    search = null;
    // Used to sort the selected/registered clients first
    initallySelectedClients = [];
    // Used to cross cancelled registrations
    cancelledClients = {};

    @Prop() event;

    created() {
        this.fetchClients();
        this.fetchGroups();
    }

    mounted() {
        // Populate the modal on edit
        if (this.event) {
            this.fields = {
                ...this.fields,
                ...this.event,
                selectedVoucherType: this.event.voucher_type_id || 3,
                maxEventRegistrations: this.event.max_event_regs || 10,
                selectedClients:
                    this.event.event_registrations
                        ?.filter((registration) => !registration.cancelled_at)
                        ?.map((registration) => registration.client_id) || [],
            };

            this.initallySelectedClients = this.fields.selectedClients;

            this.cancelledClients = this.event.event_registrations?.reduce(
                (cancelledClients, registration) => {
                    if (registration.cancelled_at) {
                        cancelledClients[registration.client_id] = registration;
                    }
                    return cancelledClients;
                },
                {},
            );

            if (this.event.recurrence_rule) {
                this.isRecurring = true;
            }

            if (this.event.is_private) {
                this.isPrivate = true;
            }
        }
    }

    get isEdit() {
        return this.event?.id;
    }

    get voucherTypes() {
        return this.$store.state.vouchers.voucherTypes.filter((vt) => vt.max_event_regs >= 10);
    }

    async fetchClients() {
        await this.$store.dispatch(CLIENTS_FETCH, { search: this.search });
    }

    async fetchGroups() {
        await this.$store.dispatch(GROUPS_FETCH, { search: this.search });
        if (!this.search) {
            this.fields.allGroups = this.groups;
        }
    }

    get groups() {
        return this.$store.state.groups.groups;
    }

    get clients() {
        if (!this.fields.selectedClients) return [];
        // Return clients sorted by: if they are registered or not and then alphabetically

        let currentClients =
            this.$store.state.clients.clients?.sort((a, b) => {
                const isASelected = this.initallySelectedClients.includes(a.id);
                const isBSelected = this.initallySelectedClients.includes(b.id);
                if (isASelected && isBSelected) return a.full_name.localeCompare(b.full_name);
                if (isASelected && !isBSelected) return -1;
            }) || [];

        let refactoredGroups =
            this.groups?.map((item) => ({
                ...item,
                id: 'g' + item.id,
            })) || [];

        return [...refactoredGroups, ...currentClients];
    }

    get vouchers() {
        return this.$store.state.clients.vouchers;
    }

    vouchersForClient(clientId) {
        return this.vouchers[clientId]
            ? this.vouchers[clientId][this.fields.selectedVoucherType].totalCount
            : 0;
    }

    get invalid() {
        const {
            date,
            startTime,
            endTime,
            selectedVoucherType,
            title,
            emails,
            phones,
            allSelectedClients,
        } = this.fields;

        if (selectedVoucherType) {
            const voucherType = this.voucherTypes.find((vt) => vt.id === selectedVoucherType);
            const totalNumClientsInvited =
                allSelectedClients.length + emails.length + phones.length;

            if (totalNumClientsInvited > voucherType.max_event_regs) return false;
        }

        return !date || !startTime || !endTime || !selectedVoucherType || !title;
    }

    close() {
        this.$store.dispatch(CALENDAR_EVENTS_FETCH);
        this.$store.dispatch(CALENDAR_SLOTS_FETCH);
        this.$root.$emit('modal-close');
    }

    onSearch() {
        this.fetchClients();
        this.fetchGroups();
    }

    async onSubmit() {
        if (this.invalid) return;
        if (this.loading) return;

        this.loading = true;
        const response = await this.$store.dispatch(REQUEST, {
            method: this.isEdit ? 'put' : 'post',
            url: this.isEdit ? `events/${this.event.id}` : 'events',
            data: {
                start_time: moment(this.fields.startTime)
                    .set({
                        year: this.fields.date.year(),
                        month: this.fields.date.month(),
                        date: this.fields.date.date(),
                        seconds: 0,
                    })
                    .utc(),
                end_time: moment(this.fields.endTime)
                    .set({
                        year: this.fields.date.year(),
                        month: this.fields.date.month(),
                        date: this.fields.date.date(),
                        seconds: 0,
                    })
                    .utc(),
                voucher_type_id: this.fields.selectedVoucherType,
                max_event_regs: this.fields.maxEventRegistrations,
                recurrence_rule: this.isRecurring ? this.fields.recurrence_rule : null,
                is_private: this.isPrivate,
                update_mode: this.fields.recurrenceUpdateMode,
                title: this.fields.title,
                location: this.fields.location,
                notes: this.fields.notes,
                is_group_session: true,
                client_ids: this.fields.allSelectedClients,
                client_emails: this.fields.emails,
                client_phonenumbers: this.fields.phones,
            },
        });

        this.loading = false;

        if (response.status === 201) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.class_modal.create_success',
            });
            this.close();
        }
        if (response.status === 200) {
            this.$store.dispatch(VALIDATION_CREATE_MESSAGE, {
                type: 'success',
                message: 'professional.class_modal.update_success',
            });
            this.close();
        }
    }

    openEventDeleteDialog() {
        this.$root.$emit('modal-open', {
            modal: EventDeleteDialog,
            props: {
                event: this.event,
                callback: () => {
                    this.close();
                },
            },
        });
    }

    get recurrenceUpdateModes() {
        return [
            { value: 'this', label: this.$t('professional.recurrence_update_modes.this') },
            { value: 'future', label: this.$t('professional.recurrence_update_modes.future') },
            // { value: 'all', label: this.$t('professional.recurrence_update_modes.all') },
        ];
    }

    selectClient() {
        let selectedGroups = this.fields.selectedClients.filter((item) => typeof item === 'string');
        const clientIds = this.fields.selectedClients.filter((item) => typeof item !== 'string');

        if (selectedGroups) {
            selectedGroups = this.fields.allGroups.filter((g) =>
                selectedGroups.some((item) => 'g' + g.id === item),
            );
        }

        const groupClientIds = selectedGroups.flatMap((group) =>
            group.clients.map((client) => client.id),
        );

        this.fields.allSelectedClients = Array.from(new Set([...groupClientIds, ...clientIds]));
    }
}
</script>
